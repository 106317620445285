<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title mt-4">
              <i class="fa-solid fa-graduation-cap"  style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" class="mt-4" @click="gpath_careerAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="col-7 col-sm-4 col-md-3 col-lg-3 mb-3">
                <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button" />
                <CsvUpload :propModulePrefix="cvModulePrefix" class="btn Download_button"  />
              </b-col>
              <b-col class="col-5 col-sm-3 col-md-2 col-lg-3 mb-2">
                <VueJsonToCsv :json-data="gpath_careerObjList" csv-title="gpath_careers" v-if="gpath_careerObjList && gpath_careerObjList.length">
                  <b-btn variant="primary" class="Download_button">CSV</b-btn>
                </VueJsonToCsv>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="gpath_careerObjList && gpath_careerObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="gpath_careerObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                <template v-slot:cell(image)="data">
                    <img :src="data.item.image" style="background-color: #e52e2c; width: 50px; height: 50px;">
                  </template>
                  <template v-slot:cell(name)="data">
                    <p>{{data.item.name}}</p>
                    <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" title="Edit Career Name" @click="gpath_careerEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" title="View GPaths Career Type" @click="gpath_career_list(data.item)"><i class="fa-solid fa-graduation-cap" aria-hidden="true"></i></b-button>
                    <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" title="View GPaths Universities" @click="gpath_career_univ()"><i class="fa fa-university" aria-hidden="true"></i></b-button>
                    <b-button variant="iq-bg-danger actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="showGPathCareerDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelGPathCareerAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl">
      <GPathCareerAdd :propOpenedInModal="true" @emitCloseGPathCareerAddModal="closeGPathCareerAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpath_careerAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathCareerAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelGPathCareerEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <GPathCareerEdit :propOpenedInModal="true" :propGPathCareerObj="gpath_careerEditObj" @emitCloseGPathCareerEditModal="closeGPathCareerEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpath_careerEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathCareerEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelGPathCareerDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelGPathCareerDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="gpath_careerDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { GPathCareers } from "../../../FackApi/api/GPathCareer"
import GPathCareerAdd from "./GPathCareerAdd"
import GPathCareerEdit from "./GPathCareerEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"

export default {
  name: "GPathCareerList",
  components: {
    GPathCareerAdd,
    GPathCareerEdit,
    CsvDownload,
    CsvUpload
  },
  data () {
    return {
      cvModuleFile: "gpath_careers",
      cvModulePrefix: "GPC",
      apiName: "gpath_careers_list",
      cvCardTitle: "GPaths Career",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit GPaths Career",
      cvAddModalHeader: "Add GPaths Career",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPathCareer List Response",
      showModelGPathCareerAdd: false,
      showModelGPathCareerEdit: false,
      showModelGPathCareerDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "image", key: "image", class: "", sortable: true },
        { label: "name", key: "name", class: "text-left align-text-top", sortable: true },
        { label: "description", key: "description", class: "text-left align-text-top", sortable: true }
      ],
      gpath_careerObjList: null,
      gpath_careerEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      delObj: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    this.gpath_careerList()
  },
  methods: {
    /**
     * gpath_careerList
     */
    async gpath_careerList () {
      try {
        let gpathCareerListResp = await GPathCareers.gpath_careerList(this)
        if (gpathCareerListResp.resp_status) {
          this.gpath_careerObjList = gpathCareerListResp.resp_data.data
          this.totalRows = gpathCareerListResp.resp_data.count
        }
        else {
          this.toastMsg = gpathCareerListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at gpath_careerList() and Exception:", err.message)
      }
    },
    /**
     * gpath_career_list
     */
    gpath_career_list (typeName) {
      this.$router.push(`/gpath_career_type_list/${typeName.name}`)
    },
    /**
     * gpath_career_univ
     */
    gpath_career_univ () {
      this.$router.push("/gpath_career_univ_list")
    },
    /**
     * gpath_careerAdd
     */
    gpath_careerAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpath_career_add")
        }
        else {
          this.showModelGPathCareerAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_careerAdd() and Exception:", err.message)
      }
    },
    /**
     * gpath_careerEdit
     */
    gpath_careerEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpath_career_edit/" + this.gpath_careerEditObj.gpc_id)
        }
        else {
          this.gpath_careerEditObj = item
          this.showModelGPathCareerEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_careerEdit() and Exception:", err.message)
      }
    },
    /**
     * showGPathCareerDeleteDialog
     */
    showGPathCareerDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelGPathCareerDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showGPathCareerDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * gpath_careerDelete
     */
    async gpath_careerDelete () {
      try {
        let gpathCareerDelResp = await GPathCareers.gpath_careerDelete(this, this.delObj.gpc_id)
        await ApiResponse.responseMessageDisplay(this, gpathCareerDelResp)

        if (gpathCareerDelResp && !gpathCareerDelResp) {
          this.showModelGPathCareerDelete = false
          return false
        }

        let index = this.gpath_careerObjList.indexOf(this.delObj)
        this.gpath_careerObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelGPathCareerDelete = false
      }
      catch (err) {
        console.error("Exception occurred at gpath_careerDelete() and Exception:", err.message)
      }
    },
    /**
     * closeGPathCareerAddModal
     */
    closeGPathCareerAddModal (gpathCareerAddData) {
      try {
        if (gpathCareerAddData) {
          if (this.gpath_careerObjList && this.gpath_careerObjList.length >= 1) {
            let gpathCareerObjLength = this.gpath_careerObjList.length
            let lastId = this.gpath_careerObjList[gpathCareerObjLength - 1]["id"]
            gpathCareerAddData.id = lastId + 1
          }
          else {
            this.gpath_careerObjList = []
            gpathCareerAddData.id = 11111
          }

          gpathCareerAddData.created_on = moment()
          this.gpath_careerObjList.unshift(gpathCareerAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelGPathCareerAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closegpath_careerAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeGPathCareerEditModal
     */
    closeGPathCareerEditModal () {
      try {
        this.showModelGPathCareerEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeGPathCareerEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
